import { Component, OnInit, Input } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css']
})
export class ProductCardComponent implements OnInit {

  @Input() productName: string;
  @Input() description: string;
  @Input() imgName: string;
  @Input() url: string;
  @Input() buttonText: string;
  @Input() packageImageLink: string;

  constructor() { 

  }


  ngOnInit(): void {
  }

 btnClick() {
   window.open(this.url);
 }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-title-banner',
  templateUrl: './title-banner.component.html',
  styleUrls: ['./title-banner.component.css']
})
export class TitleBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  btnOnClick() {
    window.open('https://blog.iautomate.design');
  }
}

<div style="margin: auto;">
<mat-card id="card"> 
  
  <div id="card-content">
    <img id="card-image" src="../assets/{{imgName}}.svg"/>  
    <h2>{{productName}}</h2>
    <img style="margin-bottom: 1em" src={{packageImageLink}} />
    <p style="font-size: large; text-align: center;">{{description}}</p>    
    <mat-card-actions id="card-footer">      
      <button mat-stroked-button id="button" (click)="btnClick()">{{buttonText}}</button>
    </mat-card-actions>
  </div>

</mat-card>
</div>




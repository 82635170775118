<!-- <div id="review-div">
  <div id="review" class="elfsight-app-aca0a3ac-e247-4a2b-909a-60949a4ca7d3"></div>
</div>
<div id="review-cover"></div> -->
<div id="layout">
  <!-- <hr style="margin-bottom: 2em; width: 70vw"/> -->
  <div id="logo">
    <img src="../assets/logo - white.svg" style="height: 80px;"/>
  </div>
  <div id="footer-text">
    <h3>iAutomate Design</h3>
    <a href="mailto:info@iautomatedesign.com" style="color:white">info@iautomatedesign.com</a>
    <!-- <br />
    <a href="mailto:support@iautomate.design" style="color:white">support@iautomate.design</a> -->
  </div>
</div>

<div id="content">
  <h1 style="color: var(--charcoal); text-align: center;">Autodesk Inventor&reg; Add-ins</h1>
  <!-- <h2>Compatible with Inventor 2019, 2020, 2021, and 2022</h2> -->
  <!-- <h3>These addins are no longer being supported. They are free to use as is.</h3> -->
  
  <div id="products">
  
    <app-product-card 
        productName="View Reference"
        imgName="view-reference"
        description="Add references to Detail, Section, Projected, and Auxiliary View call 
                      outs and labels indicating what sheet the view is located on and what sheet the parent view is located on."
        url="https://apps.autodesk.com/INVNTOR/en/Detail/Index?id=5865579890990954428&appLang=en&os=Win64"
        buttonText="View on Autodesk App Store">
    </app-product-card>

    <app-product-card 
        productName="Sheet List"
        imgName="sheet-list"
        description="Creates a table with sheet names and numbers on the first page of a drawing document."
        url="https://apps.autodesk.com/INVNTOR/en/Detail/Index?id=5342673156831821071&appLang=en&os=Win64"
        buttonText="View on Autodesk App Store">
    </app-product-card>
    
    <app-product-card 
        productName="Sheet Settings"
        imgName="sheet-settings"
        description="Change the Title Block and other standard Sheet Settings for current and all sheets in one window."
        url="https://iautomatedesign.sharepoint.com/:u:/s/Addins/EUdytd9nV4dFjZvrUZYFj6MBmMfy45BWCQSx5f2wqtTIyw?e=AneZXH"
        buttonText="Download Addin">
    </app-product-card>

    <app-product-card 
        productName="Place Open Component"
        imgName="place-component-plus"
        description="Easily place components you currently have open in Autodesk® Inventor® into your active assembly."
        url="https://iautomatedesign.sharepoint.com/:u:/s/Addins/EZX7bSdk7utMjCi2mRlZ56wBbfmrBV782Wik5tKDHrkNrg?e=pqI7XR"
        buttonText="Download Addin">
    </app-product-card>    
    
  </div>

  <!-- <h1 style="color: var(--charcoal); text-align: center; margin-top: 2em">NuGet Packages</h1>

  <div id="products">

    <app-product-card
        productName="AutodeskAppstore.Entitlement"
        packageImageLink="https://buildstats.info/nuget/autodeskappstore.entitlement"
        imgName="nuget-logo"
        description="A library that provides the ability to check if the user has a valid 
                      Entitlement to use an app from the Autodesk App Store."
        url="https://www.nuget.org/packages/AutodeskAppStore.Entitlement/"
        buttonText="View on NuGet.org">
    </app-product-card>

    <app-product-card
        productName="Abc123"
        packageImageLink="https://buildstats.info/nuget/Abc123"
        imgName="nuget-logo"
        description="Infinitely correlates an array of characters to a zero based index. When the index exceeds the length of the character array, a prefix is recursively added."
        url="https://www.nuget.org/packages/Abc123/#"
        buttonText="View on NuGet.org">
    </app-product-card>

  </div> -->

</div>


<div id="content">
  <div id="gradient">
    <div id="title-content">
      <img src="../assets/logo banner - white.svg" style="height: 100px" alt="iAutomate Design Cube"/>

      <!-- <h1 style="color: white; margin-top: 1em; margin-bottom: 1.5em; margin-left: 10em;">Blog</h1> -->
      <div id="banner">
        <!-- <a href="https://blog.iautomate.design" id="title-link">Blog</a> -->
        <!-- <a href="mailto:info@iautomate.design" id="title-link">Contact Us</a> -->

      </div>
    </div>  
    
  </div>
  <!-- <div id="wave-div">
    <svg id="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f3f4f5" fill-opacity="1" d="M0,224L1440,160L1440,320L0,320Z"></path></svg>
  </div> -->
</div>